/* eslint-disable no-restricted-globals */

// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { clientsClaim } from 'workbox-core';
import { ExpirationPlugin } from 'workbox-expiration';
import { precacheAndRoute, createHandlerBoundToURL } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { StaleWhileRevalidate } from 'workbox-strategies';

clientsClaim();

// Precache all of the assets generated by your build process.
// Their URLs are injected into the manifest variable below.
// This variable must be present somewhere in your service worker file,
// even if you decide not to use precaching. See https://cra.link/PWA
precacheAndRoute(self.__WB_MANIFEST);

// Set up App Shell-style routing, so that all navigation requests
// are fulfilled with your index.html shell. Learn more at
// https://developers.google.com/web/fundamentals/architecture/app-shell
const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$');
registerRoute(
  // Return false to exempt requests from being fulfilled by index.html.
  ({ request, url }) => {
    // If this isn't a navigation, skip.
    if (request.mode !== 'navigate') {
      return false;
    } // If this is a URL that starts with /_, skip.

    if (url.pathname.startsWith('/_')) {
      return false;
    } // If this looks like a URL for a resource, because it contains // a file extension, skip.

    if (url.pathname.match(fileExtensionRegexp)) {
      return false;
    } // Return true to signal that we want to use the handler.

    return true;
  },
  createHandlerBoundToURL(process.env.PUBLIC_URL + '/index.html')
);


// An example runtime caching route for requests that aren't handled by the
// precache, in this case same-origin .png requests like those from in public/
registerRoute(
    // Add in any other file extensions or routing criteria as needed.
    //   ({ url }) => url.origin === self.location.origin && url.pathname.endsWith('.png'), // Customize this strategy as needed, e.g., by changing to CacheFirst.
    new RegExp(/\.(?:png|gif|jpg|jpeg|svg)$/),
    new StaleWhileRevalidate({
        cacheName: 'images',
        plugins: [
        // Ensure that once this runtime cache reaches a maximum size the
        // least-recently used images are removed.
        new ExpirationPlugin({ maxEntries: 200 }),
        ],
    })
);

// This allows the web app to trigger skipWaiting via
// registration.waiting.postMessage({type: 'SKIP_WAITING'})
self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

// Any other custom service worker logic can go here.
registerRoute(
  new RegExp('.+/maps-api-v3/.+'),
  new StaleWhileRevalidate({
      cacheName: 'maps-api',
      plugins: [
          new ExpirationPlugin({
              maxEntries: 100,
              maxAgeSeconds: 20 * 24 * 60 * 60, // 30 Days
          }),
      ],
  })
);


// Incrementing OFFLINE_VERSION will kick off the install event and force
// previously cached resources to be updated from the network.
const OFFLINE_VERSION = 1;
const CACHE_NAME = 'offline';
const OFFLINE_URL = 'offline.html';


// Listen for install event, set callback
self.addEventListener('install', function(event) {
    event.waitUntil((async () => { // tells the browser that work is ongoing until the promise settles,
                                   // and it shouldn't terminate the service worker if it wants that work to complete.
        const cache = await caches.open(CACHE_NAME); // opening the "offline" cache
        // Setting {cache: 'reload'} in the new request will ensure that the response
        // isn't fulfilled from the HTTP cache; i.e., it will be from the network.
        await cache.add(new Request(OFFLINE_URL, {cache: 'reload'})); //add 'offline.html' into cache
    })());
    self.skipWaiting(); //To ensure that updates to the underlying service worker take effect immediately
    console.log('service worker has been installed');

});


// Listen for activate event, set callback
self.addEventListener('activate', function(event) {
    console.log('service worker has been activated');
    event.waitUntil((async () => {
        // Enable navigation preload if it's supported.
        // See https://developers.google.com/web/updates/2017/02/navigation-preload
        if ('navigationPreload' in self.registration) { // In some situations, service worker boot-up time can delay a network response.
        //NavigationPreload fixes this by allowing you to make the request in parallel with service worker boot-up.
            await self.registration.navigationPreload.enable();
        }
    })());
    // Tell the active service worker to take control of the page immediately.
    self.clients.claim(); // force the use of ServiceWorker in all open pages (skipWaiting & clients.claim are always combined)
});


// fetch event
self.addEventListener('fetch', function(event) {
    // We only want to call event.respondWith() if this is a navigation request for an HTML page.
    if (event.request.mode === 'navigate') { // if request relative to HTML file (else mode : cors to acceed APIs, same-origin : if request to another origin, return an error etc)
        event.respondWith((async () => { // Prevent the default, and handle the request ourselves.
            try {
                // First, try to use the navigation preload response if it's supported.
                const preloadResponse = await event.preloadResponse; // use the preloaded response if it's there
                if (preloadResponse) {
                    console.log("preloadresponse",preloadResponse);
                    return preloadResponse;
                }

                const networkResponse = await fetch(event.request); // use the network response
                console.log('networkresponse', networkResponse);
                return networkResponse;
            } catch (error) {
                // catch is only triggered if an exception is thrown, which is likely due to a network error.
                // If fetch() returns a valid HTTP response with a response code in the 4xx or 5xx range, the catch() will NOT be called.
                console.log('Fetch failed; returning offline page instead.', error);
                const cache = await caches.open(CACHE_NAME);
                const cachedResponse = await cache.match(OFFLINE_URL);
                return cachedResponse;
           }
      }) ());
    }

});